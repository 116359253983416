import { Grid } from "@mui/material";
import Article from "./Article";
import { projects } from "../data/projects";
export default function ProjectFeed() {
    return (
        <Grid alignItems='center' justifyContent='center' container spacing={4} maxWidth='100vw'>
            {projects.sort((a, b) => b.year - a.year).map(project => (
                <Grid key={project.key} item xs={12} xl={6} >
                    <Article {...project} path={project.key} />
                </Grid>
            ))}
        </Grid>)
}

