import { Stack, Typography } from "@mui/material";
export default function FN3() {
    return (
        <Stack spacing={6} alignItems='center' maxWidth='100vw'>
            <img className='rounded' width='100%' src='/fn3/welcome.png' alt='FleetNurse Welcome' />
            <p className='copy'>
                <h3>🩻Flatlined</h3>
                Some important context before we get started: this prototype never became an app.
                Unforunately, the startup failed while development was starting on the mobile end and prototyping was underway for the web app.
                But maybe it was for the better...?
                <h3>🧬Pre-Existing Condition</h3>
                <u><a href='https://info.fleetnurse.com/cascadefn' target='none'>FleetNurse</a></u>, a healthtech startup that solves staffing needs for medical facilities, needed to incorporate new features into their mobile and web apps.
                <br /><br />
                While the 2.0 version of their product was stable and well-designed, their operations grew to require more powerful functionality.
                Each team had a long wish list of features that would not only help them perform administrative actions, but also offer higher quality support to our client facilities and clinician workforce.
                <br /><br />
                After I delivered an <u><a href='/projects/fn_dashboard'>analytics dashboard</a></u> and <u><a href='/projects/fn_dashboard'>payroll & scheduling wizard redesign</a></u>, FleetNurse trusted me to prototype a version 3.0 for the product that implemented crucial new features.
                <h3>📋Initial diagnosis</h3>
                Building a 3.0 meant I didn't have to start from square one. I had the existing 2.0 to build off on in addition to two existing wireframes: one for the 2.0 product and one produced by a consulting firm for the 3.0 product.
                While this existing material was a great head start, I still had a number of goals to achieve:
                <ul>
                    <li>Incorporate and streamline existing 2.0 features</li>
                    <li>Implement new flows from existing wireframes</li>
                    <li>Consider business rules and product limitations</li>
                    <li>Understand user needs of FleetNurse team members, facility administrators, and clinicians</li>
                    <li>Study competitor products and similar gig apps like Uber and DoorDash</li>
                    <li>Deliver a sleek UI redesign in line with current design trends</li>
                </ul>
                <h3>🔪Making the first incision</h3>
                One of the initial and major pieces that we needed to tackle was our clinician onboarding flow.
                This was crucial to streamline since it directly affected the growth and engagement of our clinician workforce that ensures FleetNurse can fill the staffing needs of its facilities.
                <br /><br />
                For users, there was a lot of downtime in the onboarding flow and only a small percentage of users would make it all the way through.
                It was important to maximize the turnover rate while offering other avenues of engagement.
                <br /><br />
                A proposed solution was the "Break Room": a personalized feed of stories and updates within the app, right from the onboarding flows.
            </p>
            <video className='rounded' width='100%' src='/fn3/onboard.mp4' muted={true} playsInline loop autoPlay />
            <video className='rounded' width='100%' src='/fn3/onboard_p2.mp4' muted={true} playsInline loop autoPlay />
            <p className='copy'>
                <h3>👩🏾‍⚕️Somebody Get a Nurse</h3>
                One of the goals I mentioned earlier was:
                <ul>
                    <li> "Understand user needs of FleetNurse team members, facility administrators, and clinicians"</li>
                </ul>
                This was probably the most important and challenging goal because the app needed to cater to three distinct user groups.
                <br /><br />
                Understanding the FleetNurse team users, my coworkers, was by far the easiest. We were able to meet regularly and drill down into their specific needs.
                Understanding the facility admins was much trickier, though we had insight from our Scheduling team into the needs of those users.
                And understanding the clinicians (the primary users of the app) presented the most challenges.
            </p>
            <video className='rounded' width='100%' src='/fn3/shift_feed.mp4' muted={true} playsInline loop autoPlay />
            <p className='copy'>
                <br /><br />
                While we had healthcare subject matter experts in our ranks, the company was primarily located in Oregon while the majority of its users were in Texas and North Carolina.
                I did the due diligence of studying the reviews of our app and competitor apps, as well as the decisions made in competitor apps. But in an ideal situation, we would have established a strong feedback loop with dedicated users of the app, both clinician and facility admin. 
                <br /><br />
                What I realize looking back is how I fell into a trap of designing for a context that I didn't fully understand. This forced us to make a lot of potentially incorrect assumptions in the prototype phase that would have hopefully been challenged in the testing phase.
                <br /><br />
                The little feedback we were able to scrape up from meeting with clinicians was along the lines of "If there's a button, we'll click it" and "Just make it fast and easy".
            </p>
            <video className='rounded' width='100%' src='/fn3/shift_details.mp4' muted={true} playsInline loop autoPlay />
            <p className='copy'>
                <h3>🧪Latest advancements</h3>
                With the host of challenges that we faced, there was one thing that really streamlined the prototyping process.
                <br /><br />
                While I was designing this prototype, <u><a href='https://help.figma.com/hc/en-us/articles/15339657135383-Guide-to-variables-in-Figma' target='none'>Figma launched variables</a></u> - reusable values that can be applied to all kinds of design properties and prototyping actions.
                I was able to quickly pick up and implement variables into the prototype which not only reduced the number of frames I needed to create, but also allowed me to better implement conditional logic that was essential for representing a more streamlined onboarding flow depending on user selections.
            </p>
            <video className='rounded' width='100%' src='/fn3/clock_in.mp4' muted={true} playsInline loop autoPlay />
            <p className='copy'>
                <h3>💀Time of Death</h3>
                Just when the mobile prototypes were handed off to development and web prototypes were underway, the company went into cardiac arrest.
                As I mentioned at the top, this project didn't have a happy ending, but maybe it wouldn't have had one anyway. 
                There was still a lot to learn and a lot of assumptions that needed to be addressed. 
                <br /><br />
                While this project will always feel unfinished to me, it at least served as a valuable learning experience and improved my to diagnose and treat UI/UX problems.
            </p>

            <img className='rounded' width='100%' src='/fn3/fn_slider.png' alt='FleetNurse Welcome' />
            <video className='rounded' width='100%' src='/fn3/rewards.mp4' muted={true} playsInline loop autoPlay />
            <Stack spacing={4} className='rounded' direction='row' justifyContent='center' padding={2} maxWidth='100%' style={{ background: '#E5E5E5', maxWidth: '100%' }} >
                <img className='rounded' maxHeight='800px' width='50%' src='/fn3/fn_earnings.png' alt='FleetNurse Welcome' />
                <img className='rounded' maxHeight='800px' width='50%' src='/fn3/fn_health_info.png' alt='FleetNurse Welcome' />
            </Stack>

        </Stack>)
}

