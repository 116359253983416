import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ContentButton from "../../components/ContentButton";
export default function InfiniteImage() {
    return (
        <Stack alignItems='center' spacing={8}>
            <video className='rounded' width='100%' src='/infinite_demo.mp4' autoPlay playsInline muted loop />
            <p className='copy'>
                Winter term 2023 I took an Emerging Technologies course with Colin Ives at the University of Oregon.
                This course explored the use of AI tools like text-to-image and text-to-video generators to create artwork.
                <br /><br />
                In addition to Dall-E and Stable Diffusion, Colin was able to get us beta access to <u><a href="https://runwayml.com/" target="none">RunwayML</a></u>'s suite of AI tools.
                Throughout the term we experimented with these tools and were able to produce some engaging interactive experiences.
            </p>
            <iframe src="https://player.vimeo.com/video/815500492?h=fa5ac2c36d" width="100%" height="600px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            <p className='copy'>
                <h3>🛫Cleared for takeoff</h3>
                For the final project, I knew I wanted to produce something interactive, but didn't know exactly how I would leverage AI generators.
                I played with several tools within RunwayML's toolset and found inspiration in the Expand Image feature where I continuously fed each expanded image back into the machine.
            </p>
            <img className='rounded' width='100%' src='/runway.png' alt='captures' />
            <p className='copy'>
                Each iteration would expand the original image by 100% and present 4 options.
                I tried to limit my choices to this initial batch and always picked the image I thought best continued the idea and flow of the last image.
            </p>
            <video className='rounded' width='100%' src='/runway_demo.mp4' autoPlay playsInline muted loop />
            <p className='copy'>
                After several days of repeating this process, I ended up with a set of almost 700 images - each one representing a choice from 4 options.
            </p>
            <img className='rounded' width='100%' src='/captures.png' alt='captures' />
            <p className='copy'>
                <h3>💺Slight turbulence</h3>
                To construct the interactive experience, I brought the images into Processing and did my best to seamlessly stitch them together as the user scrolled in and out.
                Unfortunately, the sketch had terrible performance while running in Processing - the framerate was simply unacceptable.
                The user might as well just swipe through the photo album to get the same effect.
                This was disheartening because I had planned on adding in even more performance-tanking elements like sound effects.
                <br /><br />
                After attempting to optimize performance by reducing the images size, I recalled that there was a web version of Processing called p5.js and hoped that porting the sketch over would fix the performance issues.
                I was able to easily port the sketch over and was pleased to find that all performance issues were resolved. This also allowed me to easily host the sketch online.
            </p>
            <ContentButton title={"CHECK IT OUT"} component={Link} to={'/infinite_image'}>
                <video width='100%' src='/infinite.mp4' autoPlay playsInline muted loop />
            </ContentButton>
            <p className='copy'>
                <h3>🎧Complimentary earbuds</h3>
                To generate the audio, I found a <u><a href="https://experiments.withgoogle.com/imaginary-soundscape" target="none">Google AI experiment</a></u> that was able to analyze submitted images and pair them with a soundscape.
                I fed every 5th image into the generator and tried to select the images that would return the best results.
                In Processing, I set up each audio to loop as the user "approached" its image and added pitch and reverb effects to create a dream-like feeling.
                <h3>🗺Travel guide</h3>
                I implemented a progress bar that allowed the user to jump to any location on the infinite journey.
                Originally, I wanted to come up with clever names for each landmark in the imaginary landscape, but decided to go with the very inaccurate results of an image-to-text generator instead.
                This fit the theme of everything being slightly off, to include the interface for jumping destinations.
            </p>

            <p>

            </p>
            <img className='rounded' width='100%' src='/demo.jpg' alt='demo' />


        </Stack>)
}

