import { Stack, Typography, Box } from "@mui/material";
import SectionTitle from "../../components/SectionTitle";
import CustomLink from "../../components/CustomLink";
export default function AdminWizard() {
    return (
        <Stack spacing={4} alignItems='center'>
            <Stack maxWidth='700px' spacing={4}>
                <b>"Nothing more permanent than a temporary solution"</b>
                <p className = 'copy'><u><a href='https://info.fleetnurse.com/cascadefn' target='none'>FleetNurse</a></u>, a healthtech startup that solves staffing needs for medical facilities, needed a tool to help automate its payroll and scheduling procedures.
                    It hired a consulting firm to create a web app in <u><a target='none' href='https://bubble.io/'>Bubble</a></u>, a full stack no-code platform.
                    <br /><br />
                    While this web app, dubbed "🧙‍♂️The Wizard", empowered FleetNurse staff to better manage shifts and payments, it was only meant to be a temporary solution that prioritized functionality over usability.
                    <br /><br />
                    As the long-term solution became more and more long-term, it was clear that 🧙‍♂️The Wizard's major design flaws needed to be addressed.
                    Our internal users frequently reported frustrating bugs and requested much needed quality-of-life changes. 
                    They dreaded using a tool that ensures hundreds of nurses are paid on time for their shifts.
                    <br /><br />
                    With the consulting firm no longer involved in the development of the tool, FleetNurse needed an internal team member to understand and maintain this tool at risk of having to revert to primitive payroll processes.
                    I stepped up to learn Bubble's no-code systems with the goal of stabilizing 🧙‍♂️The Wizard's functionality, and improving its usability.
                </p>
                <h3>🎓Wizarding school</h3>
                <p className = 'copy'>
                    Learning a new platform as you actively make changes to a live app is a potentially dangerous task. I took extra care in avoiding breaking anything I wouldn't know how to fix, ensuring the use of backups and testing.
                    I decided to start small with cosmetic changes and build up to improving workflows.
                    <br /><br />
                    A majority of my initial efforts were spent simply naming and organizing elements within Bubble.
                    It is often a good idea to name and comment things in case you need to hand off development to another party!
                    <br /><br />
                    I made small, high-impact improvements by removing unneeded/unused elements and leveraging basic features offered in Bubble like establishing reusable components, breakpoints, and design rules.
                    <br /><br />
                    The app started to look as good as our competitors' internal admin tools.
                </p>
                <h3>💫Solution ritual</h3>
                <p className = 'copy'>
                    As good as the app looked, the more important matter was the needs of our payroll and scheduling staff.
                    My Bubble skills rapidly improved until I was able to untangle the mess of workflows and API calls connected to third-party apps - your typical start-up tech stack.
                    I was finally able to make headway in resolving long unresolved bugs.
                    <br /><br />
                    I established a Slack channel where I gathered feedback from stakeholders, clarified their needs, and communicated limitations.
                    This line of communication paired with team meetings enabled me to understand their pain points and requirements effectively.
                    <br /><br />
                    Users would ask if it was possible to implement a certain functionality. I would investigate, then report back with an implementation or an explanation as to why it is not possible.
                    This back-and-forth put our internal users at ease with the app and aided their engagement with its systems.
                    <br /><br />
                    Beyond specific user requests, I introduced new features to streamline processes and improve overall efficiency.
                    <br /><br />
                </p>
                <h3>💀Portent of Doom</h3>
                <p className = 'copy'>
                    My close understanding of the app and its processes not only empowered me to improve its usability, but also to identify critical flaws that would only become apparent at a future date.
                    If these flaws went unresolved, FleetNurse's payroll system would have created incorrect records and potentially incorrect payments.
                    <br /><br />
                    To address these critical, time-sensitive flaws, I planned and implemented timely solutions that allowed the app to maintain functionality.
                </p>
                <h3>🌄Happily ever after</h3>
                <p className = 'copy'>
                    While I started small, my team's confidence in my skills helped me to achieve the goal of improving the 🧙‍♂️Wizard's functionality and usability.
                    <br /><br />
                    The redesigned 🧙‍♂️Wizard not only addressed immediate usability issues but also prevented potential dysfunction due to critical flaws.
                    FleetNurse employees reported a considerable improvement in workflow efficiency, with fewer errors and frustrations encountered during daily operations.
                </p>
                <h3>🔮Ponderings</h3>
                <p className = 'copy'>
                    While no-code platforms like Bubble offer convenience in app development, it is important to consider long-term implications and adhere to best practices in design and development.
                    However it does speak to Bubble's credit how easy their platform was to pick up and understand.
                    The primary difficulty in remedying this app was addressing the byproducts of its hasty creation.
                    <br /><br />
                    As for our internal users, their frustrations might have been mitigated by more productive communication with the original developers.
                    Having me as an internal point of contact who was invested in improving the app and listening to their feedback helped them engage with the app and get invested in its improvement.
                </p>
            </Stack>

        </Stack >)
}

