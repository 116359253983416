import React from 'react';
import { Avatar, Button, Container, Stack, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import FadeUp from '../components/FadeUp';
import Contacts from '../components/Contacts';
import { useTheme, useMediaQuery } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
const AboutMe = () => {
    const theme = useTheme()
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Container maxWidth='md'>
            <Grid pt={12} container direction={isMdUp ? 'row' : 'column'} alignItems="flex-start" spacing={2} mt={'0px'}>
                <Grid item xs={12} md={6} >
                    <FadeUp delay={100}>
                        <img alt="profile pic" src="portrait.jpg" className='rounded luminosity' style={{ width: '100%' }} />
                    </FadeUp>
                </Grid>

                <Grid item xs={12} md={6} >
                    <FadeUp delay={300}>
                        <Stack spacing={3}>
                            <Typography variant='h2' sx={{ fontWeight: '700' }}>
                                Hello!
                            </Typography>
                            <Typography textAlign='left' >
                                I'm Louis La Riccia (he/him), a design-focused technical creative with a knack for problem-solving. I've honed my skills in crafting compelling visual narratives through education and hands-on experience in a wide array of professional settings. <br /><br />
                                With a background in creative coding, reverse engineering, and web development, I thrive at the crossroads of code and design.<br /><br />
                                Let's team up to create something extraordinary.
                            </Typography>
                            <Stack alignItems='flex-start' justifyContent={'center'} spacing={2}>
                                <Contacts />
                                <Button startIcon={<OpenInNewRoundedIcon />} sx={{ width: '100%', background: `${theme.palette.background.default}`, color: `${theme.palette.text.primary}`, borderRadius: '10px', fontWeight: 700 }} className='shadow' component={Link} target='none' to='https://drive.google.com/file/d/11wS4SMStW_W_ehOO4SBA7hTO7O9e28Bn/view?usp=sharing'>
                                    Resume
                                </Button>
                            </Stack>
                        </Stack>
                    </FadeUp>
                </Grid>
            </Grid>
            <Stack alignItems='center' spacing={10} pt={8}>
                <FadeUp delay={500}>
                    <Stack style={{ width: '100%' }} justifyContent='flex-start' alignItems='flex-start' spacing={8}>
                        <div style={{ width: '100%' }}>
                            <Stack style={{ width: '100%' }} direction='row' justifyContent='space-between' alignItems='center'>
                                <Typography textAlign='left' variant='button'>
                                    University of Oregon
                                </Typography>
                                <Typography variant='button'>
                                    2024
                                </Typography>
                            </Stack>
                            <Stack >
                                <Typography textAlign='left' variant='body' style={{ fontWeight: '600', fontSize: '24px' }}>
                                    Bachelor of Arts
                                </Typography>
                                <Typography textAlign='left' variant='caption' component={Link} to="https://artdesign.uoregon.edu/art/undergrad/ba-bs-art-technology" target='none'>
                                    Art & Technology
                                </Typography>
                                <Typography textAlign='left' variant='caption'>
                                    Computer Science
                                </Typography>
                            </Stack>
                        </div>

                        <div style={{ width: '100%' }}>

                            <Stack style={{ width: '100%' }} direction='row' justifyContent='space-between' alignItems='center'>
                                <Typography textAlign='left' variant='button'>
                                    FleetNurse Inc.
                                </Typography>
                                <Typography variant='button'>
                                    2022 - 2024
                                </Typography>
                            </Stack>
                            <Typography textAlign='left' style={{ fontWeight: '600', fontSize: '24px' }}>
                                UI/UX and Front End Developer
                            </Typography>
                            <Typography textAlign='left' style={{ fontWeight: '600', fontSize: '24px' }}>
                                Data Analyst
                            </Typography>


                        </div>
                        <div style={{ width: '100%' }}>

                            <Stack style={{ width: '100%' }} direction='row' justifyContent='space-between' alignItems='center'>
                                <Typography textAlign='left' variant='button'>
                                    U.S. Air Force
                                </Typography>
                                <Typography variant='button'>
                                    2014 - 2020
                                </Typography>
                            </Stack>
                            <Typography textAlign='left' style={{ fontWeight: '600', fontSize: '24px' }}>
                                Cryptologic Language Analyst
                            </Typography>

                        </div>

                    </Stack>
                </FadeUp>
            </Stack>
        </Container >
    );
};

export default AboutMe;