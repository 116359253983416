import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Stack, Avatar, AppBar, Hidden } from '@mui/material';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import CustomLink from './CustomLink';
const Navbar = ({ toggleDarkMode, darkMode, isDarkMode }) => {

    const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 0)');
    const [bgTransColor, setBgTransColor] = useState('rgba(0, 0, 0, 0)')
    useEffect(() => {
        const handleScroll = () => {
            const maxScroll = 4800; // Adjust this value to control when the background becomes fully opaque
            const scrollY = window.scrollY;
            const opacity = Math.min(scrollY / maxScroll, 0.5);
            const baseColor = isDarkMode ? '#000000' : '#ffffff'; // Use the theme's background color
            const rgbaColor = baseColor.includes('rgba') ? baseColor : `rgba(${parseInt(baseColor.slice(1, 3), 16)}, ${parseInt(baseColor.slice(3, 5), 16)}, ${parseInt(baseColor.slice(5, 7), 16)}, ${opacity})`;
            setBgColor(rgbaColor);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isDarkMode]);

    return (
        <AppBar position="fixed" sx = {{backgroundColor: 'none'}} style={{ top: 0, zIndex: 1000, boxShadow: 'none', backgroundImage: `linear-gradient(to right, ${bgColor} 0%, ${bgTransColor} 33%, ${bgTransColor} 66%, ${bgColor} 100%)`, backgroundColor: 'transparent' }}>

            <Stack
                maxWidth
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                m={2}>
                <CustomLink to={'/'}>
                    <Stack className='luminosity' direction='row' alignItems='center' spacing={2}>
                        <Avatar src='/portrait_square.jpg' className='luminosity' />
                        <Hidden smDown>
                            <Typography variant="h6">Louis La Riccia</Typography>
                        </Hidden>
                    </Stack>
                </CustomLink>


                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography component={CustomLink} to={'/projects'}>
                        Projects
                    </Typography>
                    <Typography component={CustomLink} to={'/gallery'}>
                        Gallery
                    </Typography>
                    <Typography component={CustomLink} to={'/about'}>
                        About
                    </Typography>
                    <IconButton color="inherit" onClick={toggleDarkMode}>
                        {darkMode ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
                    </IconButton>
                </Stack>


            </Stack>
        </AppBar >
    );
};

export default Navbar;