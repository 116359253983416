import { Stack, Typography } from "@mui/material";
export default function CompanyDashboard() {
    return (
        <Stack alignItems = 'center'>
            <img width='100%' src='/dashboard.png' alt='FleetNurse Dashboard' />
            <p className='copy'><u><a href='https://info.fleetnurse.com/cascadefn' target='none'>FleetNurse</a></u>, a healthtech startup that solves staffing needs for medical facilities, needed a way to track, visualize, and analyze its company metrics.
                <br /><br />
                While the company was gathering basic stats from each team, leadership still felt like they were "flying in the blind" and needed a better way to understand the company's operational metrics on a team basis and as a whole.
                <br /><br />
                FleetNurse leadership understood the importance of data analysis for informed decision-making, business strategy, and improving performance and revenue.
                They tasked me with delivering an analytics dashboard that serves as a single source of truth and consolidated company-wide statistics while allowing the user to control factors like date range, clinician type, facility type, and geographic region.
                <h3>📊Column A or Column B</h3>
                There are many platforms capable of delivering data solutions, each with their own benefits and limitations. <u><a href='https://www.tableau.com/' target='none'>Tableau</a></u> was a leading choice because it is easy to learn and I had a free student license at the time.
                <br /><br />
                I narrowed it down to two options:
                <ol>
                    <li>
                        Offline solution: I manually update the dashboard at a certain cadence and share it to the team as a packaged workbook. Team members view it in Tableau Reader (free). No subscription required.
                    </li>
                    <li>
                        Live solution: Dashboard is hosted on Teableau Cloud. Team members can easily access dashboard in browser. Subscription required. Additional editors/viewers introduce additional costs.
                    </li>
                </ol>
                I presented both options and their pros/cons to leadership. They went with the offline, more cost-effective solution, and we agreed on weekly updates.
                <h3>🔎Data diving</h3>
                With a platform set, I needed to track down all available company data and understand how to access and export it.
                FleetNurse's operations were spread across several platforms like Hubspot, JIRA, PostgreSQL, Bubble, and Quickbooks.
                <br /><br />
                Additionally, the company wanted to track JIRA changelog data since it offered insight into its credentialing operations.
                This data was not easily accessible via JIRA's frontend so I wrote a node js script that collected changelog records for thousands of clinicians via JIRA's API.
                <br /><br />
                It was difficult at first to link all the data to each other in Tableau's backend; several records ended up duplicated or unused, resulting in large file sizes.
                Once I focused on what the user was able to control, particularly the date, it became apparent how all the data points should connect together.
                <br /><br />
                Each week I would export data from each data source and synthesize all stats into a single master event list using another custom script.
                Each event was linked to a date, facility ID, clinician ID, and zip code when available.
                <br /><br />
                I made sure to document my workflow in case someone else needed to take over the process.

                <h3>📈Trending positive</h3>
                Through multiple rounds of meeting with our teams, I was able to pinpoint the most important stats that represent each team's operations.
                Each team's stats became its own sheet within the workbook, and each sheet was summarized on the main executive dashboard view.
            </p>
        </Stack>)
}

