import { Container, Stack, Chip, Typography, Box, Button, Fade } from "@mui/material";
import { useParams } from 'react-router-dom';
import { projects } from "../../data/projects";
import CustomLink from "../../components/CustomLink";
import FadeUp from "../../components/FadeUp";
import FadeInSection from "../../components/FadeInSection";
import Carousel from "../../components/Carousel";
export default function ProjectPage() {

    const { id } = useParams();
    projects = projects.sort((a, b) => b.year - a.year)
    const project_index = projects.findIndex(p => p.key === id)
    const project = projects[project_index]
    const next_project = projects[project_index + 1] ?? null
    const prev_project = projects[project_index - 1] ?? null

    return (
        <div>
            <Fade in={true} style={{ transitionDelay: '0ms', transitionDuration: '1000ms' }}><img width='100%' height='50%'
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    zIndex: '-1',
                    webkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
                    maskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0))',
                    objectFit: 'cover'
                }} alt={project.title} src={project.img} /></Fade>
            <div>
                <Box height='25vh' />
                <Stack maxWidth='100vw' alignItems='center' justifyContent='center' p={2} spacing={2}>
                    <Stack maxWidth='1000px' spacing={2} alignItems='center' >
                        <Stack alignItems='flex-start' maxWidth='700px' spacing={2}>
                            <Typography textAlign={'left'} variant='h3' sx={{ fontWeight: 600 }}>{project.title}</Typography>
                            <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap >
                                {project.skills.map(skill => (
                                    <Chip key = {`${skill}`} label={skill} size='small' />
                                ))}
                            </Stack>
                            <Typography sx={{ opacity: '50%', fontWeight: 600 }} variant='h6' textAlign={'left'}>{project.subtitle}</Typography>
                            <div width='100%' height='1px' className='shadow' />
                        </Stack>
                        <Box height='12vh' />
                        {project.content}
                        <Box height='2vh' />
                        <div width='100%' height='1px' className='shadow' />
                    </Stack>
                    <Stack maxWidth='1000px' spacing={2} mt={20}>
                        <Typography sx={{ fontWeight: '600' }} variant='h4'>More cool projects</Typography>
                        <Carousel projects={projects.filter(p => p.key !== id).sort((a, b) => Math.random() - 1)} />
                        <Box height='5vh' />
                        <Stack direction='row' justifyContent='space-between'>
                            {[prev_project, next_project].map((p, i) => (
                                p ? <CustomLink key = {p?.key} to={`/projects/${p?.key}`}>
                                    <Stack>
                                        <Typography textAlign={i === 0 ? 'left' : 'right'} variant='caption'>
                                            {`${i === 0 ? 'Previous' : 'Next'}`}
                                        </Typography>
                                        <Typography textAlign={i === 0 ? 'left' : 'right'} >
                                            {p?.title}
                                        </Typography>
                                    </Stack>
                                </CustomLink> : <Box key ={p?.key} />

                            ))}
                        </Stack>
                    </Stack>
                    <Button component={CustomLink} to='/gallery'>
                        Check out the Gallery
                    </Button>

                </Stack>

            </div>
        </div>
    )
}

