import { Container, Stack, Typography, Box, Button } from "@mui/material";
import FadeUp from "../components/FadeUp";
import Three from "../components/Three";
import Projects from "./Projects";
import { Link } from 'react-router-dom';
import CustomLink from "../components/CustomLink";
import Contacts from "../components/Contacts";
import { useTheme } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
export default function Home() {
    const theme = useTheme()
    return (
        <div>
            <div id='canvas-container' style={{ opacity: '20%' }}>
                <Three />
            </div>
            <div style={{ position: 'fixed', background: 'linear-gradient(45deg, rgba(255, 0,0, 0.1),  rgba(0, 0, 255, 0.1))', width: '100%', height: '100%', zIndex: '-1' }} />
            <Stack alignItems='center' style={{ maxWidth: '100vw' }}>
                <Box
                    spacing={2}
                    display='flex'
                    minHeight='90vh'>
                    <FadeUp delay='1500ms'>
                        <Stack spacing={3} m={3}>
                            <CustomLink to='/about'><Typography textAlign='left' style={{ fontWeight: '600', fontSize: 'clamp(16px, 8vw, 48px)' }}>I'm Louis La Riccia</Typography></CustomLink>
                            <Typography textAlign='left' style={{ fontSize: 'clamp(16px, 3vh, 24px)' }} sx={{
                                maxWidth: '800px'
                            }}>I develop, design, and create all kinds of stuff. My specialty is in&nbsp;
                                <CustomLink to='/projects/fleet_nurse_3'>UI/UX design</CustomLink>
                                ,&nbsp;<CustomLink to='/gallery'>visual design</CustomLink>, and&nbsp;
                                <CustomLink to='/projects/piano_arcade'>interactive experiences</CustomLink>.
                            </Typography>
                            <Typography textAlign='left' style={{ fontSize: 'clamp(16px, 2vh, 24px)', maxWidth: '700px' }} sx={{
                                flexWrap: 'wrap',
                                maxWidth: '800px'
                            }}>
                                Most recently, I graduated from the University of Oregon with a&nbsp;<a target='none' href='https://artdesign.uoregon.edu/art/undergrad/ba-bs-art-technology'>Bachelor's in Art & Technology</a>&nbsp;while working at&nbsp;<a target='none' href='https://www.fleetnurse.com'>a health-tech startup</a>&nbsp;for 2 years.
                            </Typography>
                            <Stack direction='row' spacing={2} useFlexGap flexWrap="wrap">
                                <Button startIcon={<OpenInNewRoundedIcon />} sx={{ maxWidth: '300px', background: `${theme.palette.background.default}`, color: `${theme.palette.text.primary}`, borderRadius: '10px', fontWeight: 700 }} className='shadow' component={Link} target='none' to='https://drive.google.com/file/d/11wS4SMStW_W_ehOO4SBA7hTO7O9e28Bn/view?usp=sharing'>
                                    Resume
                                </Button>
                                <Contacts />
                            </Stack>

                        </Stack>
                    </FadeUp>
                </Box>
                <Projects />
            </Stack>
        </div>)
}
