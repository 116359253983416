import { Grid, Stack, Typography, Box, ImageList, ImageListItem } from "@mui/material";
import SectionTitle from "../../components/SectionTitle";
import ContentButton from "../../components/ContentButton";

const itemData = ['fir', 'lava', 'lethargy', 'money', 'nice', 'schlock', 'sunglasses', 'snack', 'wrinkle'].map(name => ({ img: `/${name}.jpg`, featured: name === 'matrix' }))

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function SpreezyMart() {
    return (
        <Stack width='100%' alignItems='center'>
            <Stack alignItems='center'>
                
            <p className='copy'>
            <h3>🏪Store policy</h3>
                I took Michael Salter's Communicative Design course at the University of Oregon which is meant to emulate a design studio with multiple teams and the ultimate challenge of creating a fun little pop-up shop. 
                We landed on the idea of a parody convenience store since it offered a lot of material to riff on.
                I ended up creating 9 shirts, a logo, a few instagram animations, the website, and a 20-minute sizzle reel for the event. This was a fun 10 weeks and really challenge my design skills and ability to come up with dumb puns. 
                <br/><br/>
                One thing that was interesting to observe was the often clashing ideas of the brand as it was developing. While Salter offered a guiding light to the students, it was largely up to us to define a cohesive and engaging parody brand. An essential part of the collaboration was a shared Google Drive folder and Discord server. These served as the primordial soup of the brand and lightened the communication load.
                <br/><br/>
                It felt good to make something that someone would want to buy. The secret was to invite your friends and family to the event and guilt them into buying one of your $15 shirts. I think I sold 5/9 shirts and gifted the others. 
            </p>
            <h3>👍Social media</h3>
            <Box sx={{ width: '100%' }}>

                <Grid container rowSpacing={2} columnSpacing={2} alignItems='center' justifyContent='center'>
                    <Grid item md={6}>
                        <video width='100%' height='100%' src='/nic.mp4' muted={true} playsInline loop autoPlay />
                    </Grid>
                    <Grid item md={6}>
                        <video width='100%' height='100%' src='/24hours.mp4' muted={true} playsInline loop autoPlay />
                    </Grid>
                    <Grid item md={6}>
                        <video width='100%' height='100%' src='/spreezeeverymoment.mp4' muted={true} playsInline loop autoPlay />
                    </Grid>
                    <Grid item md={6}>
                        <video width='100%' height='100%' src='/z spin.mp4' muted={true} playsInline loop autoPlay />
                    </Grid>
                </Grid>
            </Box>

            <h3>🌭Website</h3>
            <ContentButton title='CHECK IT OUT' target='none' to={'https://sites.google.com/view/spreezymart/home'}>
                <video src='/web_preview.m4v' muted={true} playsInline loop autoPlay />
            </ContentButton>
            <h3>👕Shirt production</h3>
            <ImageList
                variant="quilted"
                cols={3}
                rowHeight={400}
            >
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            {...srcset(item.img, 121, item.rows, item.cols)}
                            alt={item.title}
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <h3>✨Event</h3>
            <video src='/timelapse.mp4' muted={true} playsInline loop autoPlay />
            <p>The event took place Tuesday, November 29, 6-8 PM at 510 Oak Street, Eugene, OR</p>
            <video src='/shirts.mp4' muted={true} playsInline loop autoPlay />
            <p>Each shirt came with a mini paper bag with a gummy hotdog in it (the hotdog was kind of a thing) and washing/care instructions</p>
            <img width='100%' height='100%' src='/spreezy_mart.png' alt='Spreezy Dog' />
        </Stack>
        </Stack>)
}

