import React from 'react';
import { Stack, Grid, Button, Box } from '@mui/material';
import CustomImageList from '../components/CustomImageList';
import FadeUp from '../components/FadeUp';
import Video from '../components/Video';
import CustomLink from '../components/CustomLink';

const itemData = [
  {
    img: '/p3.png',
    cols: 3,
    rows: 2
  },
  { img: '/face1.png' },
  {
    img: '/bev.png',
    cols: 2,
    rows: 3
  },

  {
    img: '/blink.gif',
  },
  {
    video: '/p4.mp4',
  },
  { video: '/dice.mp4' },
  { video: '/cards.mp4' },
  { video: '/wheel.mp4' },
  {
    img: '/audio.jpg',
    cols: 2,
    rows: 2
  },
  {
    video: '/peace.mp4',
  },
  { img: '/face0.png' },
  {
    img: '/notsaved.png',
    cols: 3
  },

  { img: '/face2.png' },

  {
    img: '/text.png',
    cols: 2,
    rows: 2
  },
  { img: '/face4.png' },

  {
    img: '/ps_shirt_upres.jpg',
    cols: 2,
    rows: 2
  },
  { img: '/face3.png' },

  {
    img: '/mrslappy.png',
  },
  {
    img: '/blahblahblah.jpg',
  },
  {
    img: '/nice.jpg',
  },
  {
    img: '/money_talks.jpg',
  },
  {
    video: '/spreezeeverymoment.mp4',
  },
  {
    video: '/24hours.mp4',
  },
  {
    video: '/nic.mp4',
  },
  {
    img: '/snacktown.jpg',
  },
  {
    img: '/wrinkles.jpg',
  },
  {
    img: '/5week.jpg',
  },

  {
    img: '/spreezydog2.gif',
    cols: 3
  },
  { img: '/levant.png' },
  {
    img: '/ibex.jpg',
  },
  {
    img: '/34is.jpg',
  },
  {
    video: '/wendel3.mp4'
  },
  {
    video: '/mando.mp4',
  },
  {
    img: '/silentbomb.png',
  },
  { img: '/brentrambo.gif' },
  {
    video: '/sadwatto.mp4'
  },
  {
    video: '/anakinfortnite.mp4'
  },


  {
    video: '/qr.mp4',
  },
  {
    video: '/lightsabers_square.mp4',
  },
  {
    video: '/infinite.mp4',
  },

];

const Gallery = () => {
  return (
    <Stack width='100%' alignItems='center'>
      <CustomImageList itemData={itemData} />
      {[
        "https://www.youtube.com/embed/y4ddftaxi0s?si=EMV2NU9rq02-VDnV",
        "https://www.youtube.com/embed/YgZ122Q82gY?si=Ql-yebE4PbqrDM_t",
        "https://www.youtube.com/embed/Njtw3_4lmHk?si=q269ojQA1LMlxa3k",
        "https://www.youtube.com/embed/xXi_VFOLKvg?si=0cW0eNWQUNUPQBm4",
        "https://www.youtube.com/embed/9hJqYIihUD4?si=eQa6aRE2qaSunQhn",
        "https://www.youtube.com/embed/9TrtwC_mLbI?si=MgXV8LV2eUidZqZE",
        "https://www.youtube.com/embed/wJZyzKd0pno?si=zCW1IAWsMo0uJA37",
        "https://www.youtube.com/embed/tlpN4jZ960M?si=gDWh81aLVRfGDzQ6"
      ].map(path => (
        <div
          className="video"
          style={{
            color: 'red',
            width: '100%',
            aspectRatio: 16 / 9
          }}
        >
          <iframe
            title='Youtube Video Player'
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
            src={path}
            frameBorder="0"
          />
        </div>
      ))}
      <Box mt={12}>
        <Button component={CustomLink} to='/projects'>
          View Projects
        </Button>
      </Box>
    </Stack>
  );
};

export default Gallery;