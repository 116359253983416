import { Fade, Slide } from '@mui/material';

const FadeUp = ({ delay, children }) => {
    return (
        <Slide mountOnEnter unmountOnExit in={true} direction='up' timeout={1000} style={{ transitionDelay: delay, transition: 'ease-in-out' }} easing={{
            enter: "cubic-bezier(0, 0.88, .12, 1)",
            exit: "cubic-bezier(0, 0.88, .12, 1)",
        }}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Fade in={true} style={{ transitionDelay: delay, transitionDuration: '1000ms' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                        {children}
                    </div>
                </Fade>
            </div>
        </Slide>

    )
}

export default FadeUp