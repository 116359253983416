export default function InfiniteImageExperiment() {
    return (
        <div
            className="video"
            style={{
                overflow: 'hidden',
                width: '100%',
                height: '100vh'
            }}
        >
            <iframe
                title='Infinite Image'
                style={{
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }}
                src={"https://louriccia.github.io/infiniteimage/"}
                frameBorder="0"
            />
        </div>
    )
}