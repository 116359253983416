import { Chip, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FadeInSection from './FadeInSection';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { getRandomColor } from '../utils';
import { max } from 'three/examples/jsm/nodes/Nodes.js';

const Article = ({ title, subtitle, img, path, skills, children }) => {

    const [borderColor, setBorderColor] = useState(getRandomColor());

    const handleMouseEnter = () => {
        setBorderColor(getRandomColor());
    };

    const handleMouseLeave = () => {
        setBorderColor(getRandomColor());
    };

    return (
        <FadeInSection>
            <Link className="no-hover-decoration" to={`/projects/${path}`} onClick={() => {
                window.scrollTo({
                    top: 0
                });
            }}>
                <Box className="grow-on-hover-box" style={{ borderColor: `${borderColor}`, borderRadius: '20px', backdropFilter: 'blur(5px)' }} p={3} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <Stack direction='column' spacing={2}>
                        <img alt='no_image' src={img ?? ''} style={{ borderRadius: '10px', maxHeight: '400px', objectFit: 'cover', mixBlendMode: 'difference', backgroundColor: 'transparent' }}  />
                        <Stack alignItems='flex-start' spacing={1}>
                            <Stack direction='row' spacing={1} alignItems='center' width='100%' justifyContent='space-between' useFlexGap flexWrap="wrap">
                                <Typography noWrap
                                    textAlign='left' maxWidth='100%' sx={{
                                        fontSize: 'clamp(16px, 2.5vw, 24px)',
                                        fontWeight: '600',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        textDecoration: 'none'
                                    }}>
                                    {title ?? "Title"}
                                </Typography>
                                <Stack direction='row' spacing={1} useFlexGap flexWrap="wrap" >
                                    {skills.map(skill => (
                                        <Chip key = {skill} label={skill} size='small' />
                                    ))}
                                </Stack>
                            </Stack>
                            <Typography textAlign='left'>
                                {subtitle ?? "Subtitle"}
                            </Typography>
                        </Stack>

                    </Stack>
                </Box>
            </Link>
        </FadeInSection>
    )
}

export default Article