import { Avatar, Stack, Typography, Box } from "@mui/material";
import CustomLink from "./CustomLink";
import { Link } from "react-router-dom";
import { getRandomColor } from "../utils";
import { useState } from "react";
export default function CarouselItem({ project, key }) {

    const [borderColor, setBorderColor] = useState(getRandomColor());

    const handleMouseEnter = () => {
        setBorderColor(getRandomColor());
    };

    const handleMouseLeave = () => {
        setBorderColor(getRandomColor());
    };

    return (

        <Box key = {key} sx={{ width: '220px' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Stack spacing={2}>
                <Avatar className="grow-on-hover-box" variant='square' src={project.img} alt='none' sx={{ width: '220px', height: '220px', borderRadius: '10px', borderColor: { borderColor }, borderWidth: '2px' }} component={Link} to={`/projects/${project.key}`} onClick={() => {
                    window.scrollTo({
                        top: 0
                    });
                }} />
                <Stack spacing={1}>
                    <CustomLink to={`/projects/${project.key}`}>
                        <Typography sx={{
                            fontWeight: '700',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                        }}

                        >
                            {project.title}
                        </Typography>
                    </CustomLink>
                    <Typography sx={{
                        opacity: '50%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical'
                    }}>
                        {project.subtitle}
                    </Typography>
                </Stack>

            </Stack>
        </Box>
    )
}

