import { Link } from 'react-router-dom';

const CustomLink = ({ key, to, target, children }) => {
    return (

        <Link key = {key} style={{
            '&:hover': {
                textDecoration: 'underline'
            }
        }} to={to} target={target} onClick={() => {
            window.scrollTo({
                top: 0
            });
        }}>
            {children}
        </Link>
    )
}

export default CustomLink