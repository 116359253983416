import { Box, Stack, Typography } from "@mui/material";
import CarouselItem from "./CarouselItem";
export default function Carousel({ projects }) {
    return (
        <Box sx={{
            pt: 1, pl: 1,
            maxWidth: '100%', overflowX: 'scroll',
            '&:hover': {
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'black',
                    borderRadius: '10px'
                }
            },
            '&::-webkit-scrollbar': {
                backgroundColor: 'transparent',
                width: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent'
            }
        }}>
            <Stack

                spacing={4}
                direction='row'
            >
                {projects.map(project => (
                    <CarouselItem key = {project} project={project} />
                ))}
            </Stack>
            <Box sx={{ position: 'absolute', top: 0, left: 0, minHeight: '24px', width: '100%' }} />
        </Box>

    )
}

