import { Container, Stack, Typography } from "@mui/material";
import Contacts from "./Contacts";
import { useLocation } from "react-router-dom";
export default function Footer() {
    const { pathname } = useLocation()
    return pathname === '/infinite_image' ? null : (
        <Container maxWidth='100%' minHeight='200px'>
            <Stack
                direction={{ sm: 'column', md: 'row' }}
                alignItems="center"
                justifyContent='space-between'
                spacing={2}
                mt={16}
                mb={1}>

                <Typography variant='caption' noWrap overflow="visible">
                    © COPYRIGHT 2024
                </Typography>
                <Contacts />

                <Typography variant='caption' noWrap overflow="visible">
                    React Three Fiber + Material UI
                </Typography>
            </Stack>

        </Container>)
}

