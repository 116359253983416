import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { React, useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './theme';
import { CssBaseline } from '@mui/material';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AboutMe from './pages/AboutMe';
import Projects from './pages/Projects';
import Gallery from './pages/Gallery';
import ProjectPage from './pages/_Projects/ProjectPage';
import InfiniteImageExperiment from './pages/Experiments/InfiniteImageExperiment';

function App() {


  const [isDarkMode, setIsDarkMode] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    // Update state based on the preferred color scheme
    setDarkMode(prefersDarkMode);

    // Listen for changes in the color scheme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event) => setIsDarkMode(event.matches);

    mediaQuery.addEventListener('change', handleChange);

    // Cleanup the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  return (
    <div className="App">
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline>
          <BrowserRouter>
            <div style={{ position: 'fixed', background: 'linear-gradient(45deg, rgba(255, 0,255, 0.1), rgba(0, 255, 255, 0.1))', width: '100vw', height: '100vh', zIndex: '-1' }} />
            <Navbar toggleDarkMode={toggleDarkMode} darkMode={darkMode} isDarkMode={darkMode} />
            <Routes>
              <Route index element={<Home />} />
              <Route path='/about' element={<AboutMe />} />
              <Route path='/projects/' element={<Projects />} />
              <Route path='/projects/:id' element={<ProjectPage />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path='/infinite_image' element={<InfiniteImageExperiment />} />
            </Routes>

            <Footer />
          </BrowserRouter>
        </CssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default App;
