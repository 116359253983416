import { Typography } from "@mui/material";
import CustomLink from "./CustomLink";
import { Link } from 'react-router-dom';

const ContentButton = ({ to, children, title, target }) => {
    return (
        <Link  target={target} className='shadow' to={to} onClick={() => {
            if (!target) {
                window.scrollTo({
                    top: 0
                });
            }

        }}>
            {children}
            <div className='overlay' sx = {{borderRadius: '10px'}}>
                <Typography className='gradientText' sx={{ color: 'white', fontWeight: '800' }} variant='h3' align='center' >{title}</Typography>
            </div>
        </Link>
    )
}

export default ContentButton