import { Stack, Box } from "@mui/material";
import Video from "../../components/Video";
export default function PianoArcade() {
    return (
        <Stack alignItems='center' spacing={4}>
            <p className='copy'>
                When I was in John Park's Projection Mapping course at the University of Oregon, I was eager to combine the artform with an instrument that I have played for a good chunk of my life. In one of the initial projects for the class I created a visualized performance of Feverkin's "Far Away From Everything" using Illustrator, AE, and Blender.
            </p>
            <div
                className="video"
                style={{
                    color: 'red',
                    width: '100%',
                    aspectRatio: 16 / 9
                }}
            >
                <iframe
                    title='Youtube Video Player'
                    style={{
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                    }}
                    src={"https://www.youtube.com/embed/YgZ122Q82gY?si=Ql-yebE4PbqrDM_t"}
                    frameBorder="0"
                />
            </div>
            <p className='copy'>
                This piece is not unlike most projection mappings that feature pianos; it marries a performance to a pretty visual. When I learned our final projects would be exhibited to the public and would need to incorporate an interactive element to fit a theme of discovery & interaction, I knew I had to build on this concept.
                <br /><br />
                What I ended up with was an interactive piano projection featuring 4 unique play modes and hidden easter eggs. This was a ton of fun to make and I would love to return to it someday. It will probably be my favorite project for a long time. I could write a lot more about what went into this project and all the unused ideas... but I'll try to keep it brief.

            </p>
            <video src='/piano_painting.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_painting_demo.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_physics_demo.mp4' muted={true} playsInline loop autoPlay />

            <p className='copy'>
                <h3>🎵Key change</h3>
                From the start I knew that I wanted the experience to contain several modes - almost like levels in a game. However, my initial ideas leaned toward much more musically challenging ideas such as pitch-guessing, memory-testing, and guitar hero-like modes.
                These modes would heavily favor users with a musical background, but for an open public experience it seemed like an unnecessary barrier to entry and was against the agreed themes of "play" and "discovery".
                <br /><br />
                What I wanted was an experience that welcomed people who had maybe never even touched a piano before to embrace the chaos of pressing a bunch of keys and abandoning musicality in favor of creating an interesting visualization or playing a game.
            </p>
            <video src='/piano_physics_demo2.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_physics_bend.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_physics.mp4' muted={true} playsInline loop autoPlay />

            <p className='copy'>
                I narrowed it down to 4 modes. The first two modes have fun visualizations and side-effects to pressing the keys, and the latter two modes have games that require a win condition to advance.
                <ul>
                    <li>🎨Paint mode: draws growing, colorful circles to visualize what the user is playing</li>
                    <li>🧑‍🔬Physics mode: spawns rectangles out of each key press depending on its velocity and duration (probably the most fun mode)</li>
                    <li>🏀Basketball mode: Try to get all the basketballs into the hoop as it keeps speeding up</li>
                    <li>🕹️Breakout mode: inspired by the breakout game, where the keys are destructible and affect your ability to move the paddle</li>
                </ul>

            </p>
            <video src='/piano_physics_basketball.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_basketball.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_basketball_demo.mp4' muted={true} playsInline loop autoPlay />

            <p className='copy'>
                <h3>🎹Masterful performance</h3>

                For a live interactive piece like this, performance and responsiveness was essential.
                This was especially a concern for the mode in which physics objects spawned every time the user released a key.
                They could spam keys and quickly create hundreds of objects, grinding the experience to a halt. Granted, there is some fun in being able to do that - testing the limits of the system - but I needed to implement some safeguards.
                <br /><br />
                For example, objects that fall out of bounds should be deleted. There was also a fun feature in which an object could spawn, hit the top, then bounce back and hit a key, spawning another object.
                I really wanted to keep this feature, since it felt like something virtual was able to affect the physical. So I added a limit to prevent too many objects from spawning more objects.
            </p>
            <video src='/piano_breakout.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_breakout_2.mp4' muted={true} playsInline loop autoPlay />

            <p className='copy'>
                <h3>🪺Easter eggs</h3>
                The keyboard was equipped with both a pitch-bender and a sustain pedal. I knew I had to include some hidden features with both of these for each mode that would significantly augment the experience.
                <br /><br />
                For the first two modes, the sustain did what the sustain does, simply holds the note. But for the two more game-like modes, it slows down time, which gives the user a significant advantage in beating the games.
                <br /><br />
                The pitch-bender changes the canvas color in the first mode, and temporarily affects gravity in the other three modes, allowing the player to "cheat" in the games.
                I really liked how these easter eggs overly rewarded the player for discovering them, creating a super satisfying moment, and allowing them to pass their hidden knowledge on.
                <br /><br />

                For the sake of testing, I added a "cheat code" that allowed me to quickly switch modes without having to reach the programmed condition. This wasn't really for the user to discover, but I did share it during the demo. Since the user could press every key and even use the pitch bender and sustain pedal, the cheat code needed to be a specific, deliberate combination of keys. I needed something that couldn't be input accidentally, but could still be input quickly and easily checked for programmatically. The code ended up being any 10 notes, each 3 notes apart.
            </p>
            <video src='/piano_breakout_bend.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_breakout_demo.mp4' muted={true} playsInline loop autoPlay />
            <video src='/piano_breakout_idle.mp4' muted={true} playsInline loop autoPlay />
            <p className='copy'>

                <h3>🔮Mapper musings</h3>


                {/* 
                What is the origin of your idea and does it relate to your own interests and excitement?
                The idea for this project originated in research for my initial project when I came across an educational use for projection mapping onto a piano.
                Similar to Guitar Hero, notes would cascade toward the player, expecting to be played.
                My idea is to expand on this concept of gamifying the piano playing experience so that it appeals to novice users who may have never played the piano before.
                This combination of making music and playing a game intertwines two of my greatest passions.

                If you were to take on the role as a bridge between a stranger in an exhibition space, and the above idea of your project, what are some of the ways in which you could build in mystery and discovery for the person as they explore your idea?

                Through the use of subtle hints and hidden modes, I hope to encourage a tabula rasa experience for a user as they explore a series of game modes and features much like they would a commercial video game.
                Achievements and easter eggs are ways that game designers reward players with fun little surprises based on their interaction with the game.

                Describe how elements to this final project connect to other pieces that you have made in the past. Where will you refine on your past successes, and where do you hope to prove yourself in new creative territory?

                This project continues the theme of using a piano keyboard to communicate my artistic ideas.
                In my past project, the keyboard was sadly non-interactive.
                So to rectify that, I want to lean in heavily to the interactive element while also improving the geometry of the projection surface.
                Rather than having it be two boring flat surfaces, I am considering introducing an array of diamond shapes that will be used in both the interface and game modes.
                <h3>Setup</h3>
                Setup
                88 key keyboard (treated surfaces?)
                Upward facing cardboard surface fixed right at end of notes, serves dual function as projection surface and preventing users from messing with keyboard parameters
                Consider array of 5 diamond shapes
                Live midi input into processing project
                Output through mad mapper and projected back onto keyboard/upper surface
                Where to project from?
                Top down
                Avoids player occluding projection but may result in bad coverage on upper surface
                Likely necessary for fingers occluding notes, don’t want shadows to be too distracting
                Upper surface probably needs to be tilted back at a considerable angle for the sake of coverage
                Measurements
                Keyboard stands at 2’ 5”
                Key length 6”
                Width 4’
                Projector height greater than 8.5’
                Cardboard height 32”
                <h3>Limitations/challenges</h3>
                Limitations/challenges
                How much midi spam can processing take
                How to avoid lag while still maintaining visual interest
                Splitting the player’s focus between the keys and the upper surface
                Performance issues with processing, may consider using desktop computer at lab rather than cheap laptop */}
            </p>


            <img width='100%' src='/project_chair.jpg' alt='Piano Arcade Dev' />
            <p>A very early test on the back of an office chair using Photoshop. This was helpful for understanding the limitations of projection mapping such as resolution and perspective.</p>
            <img width='100%' src='/project_piano_1.jpg' alt='Piano Arcade Dev' />
            <p>An simple early assignment testing out Mad Mapper's functions</p>

            <img width='100%' src='/project_demo_persp.jpg' alt='Piano Arcade Dev' />
            <img width='100%' src='/project_demo.jpg' alt='Piano Arcade Dev' />
            <p>Early on in the project, I experimented with perspective solving in order to create the illusion of extruding and removing physical space. However, this ended up not being feasible for an interactive experience.</p>


            <img width='100%' src='/project_piano_ref.jpg' alt='Piano Arcade Dev' />
            <p>A reference photo I took to understand the shapes of the piano keys. I needed to recreate every unique key shape for the sake of coloring and colliding each key separately.
                I used my personal keyboard and projector for this project, which made it much easier to make sure I had the right parameters.</p>
            <img width='100%' src='/piano_arcade_dev.png' alt='Piano Arcade Dev' />
            <p>I needed to work on the project without having a physical keyboard so I set up a dev environment using VirtualMIDI and loopMIDI to emulate a keyboard for the sake of testing.</p>

            {/* <p className='copy'>
                <h3>Resources</h3>
                Scripting Processing With MIDI : 12 Steps - Instructables
                Learn programming 101: Control Processing using a MIDI controller on Vimeo
                GitHub - shiffman/Box2D-for-Processing: Processing JBox2D helper library and examples
                Chapter 5. Physics Libraries
                5.0a: Introduction to Physics Engines Part 1 - The Nature of Code
                Virtual MIDI Piano Keyboard
                Reference / Processing.org
                Parse midi files - Libraries - Processing Foundation
                CDP-230R MIDI Implementation

                https://forum.processing.org/one/topic/concurrentmodificationexception-problem.html
                https://stackoverflow.com/questions/50013465/processing-box2d-assertion-error

                <h3>Sandbox/Demos</h3>
                <ul>
                    (on idle) bouncing dvd logo style animation that plays the note when it hits it, sort of beckons ‘come play me’
                    Physics stuff
                    reverse gravity
                    notes spawn blocks which fill up the upper projection
                    delete oldest nth note for performance purposes
                    Length of note block could be determined by duration of sustain
                    Driving a car across a randomly generated landscape from the notes
                    Pinketto, must drop a ball in to a certain slot to progress
                    Pinball,
                    Equalizer type visualization that grows bars and physics based balls that respond to velocity of note hit, could try to launch balls into a moving target basket
                    Could be more of a fluid experience that doesn’t have set scores or points, but has a sort of adventure/puzzle feel that expects different playstyles and loops back to the start
                    Each game mode could have a different instrument

                </ul>
                <h3>Game Modes</h3>
                Game modes (limit to 5)
                Canvas
                Velocity
                Sustain
                Pitch
                Diamonds - act as special areas that must be filled to progress?
                Boxes
                Pitch mod - affects gravity
                Sustain - size of box
                Velocity - initial velocity of box
                Diamonds - act as obstructions, must be destroyed to progress
                Basketball
                Pitch mod - affects height of basket
                Basket gets faster with fewer balls
                Slomo (limited use)
                Diamonds - act as obstructions
                Space Invaders
                Encroaching rows of enemies try to destroy your notes as you try to eliminate them before they reach your base
                Each note press sends out a bullet, a bullet sent out by a note will cancel an incoming one from an enemy
                Speed gets faster with fewer enemies
                Velocity - speed of bullet
                Sustain - shield (limited use)
                Diamonds - act as blocking structures that decay as they’re shot like in the original game
                Breakout
                Input method options
                Hit the note at the correct timing to avoid losing it
                hit a note to attract the paddle toward it
                Would allow more spam, multiplayer
                Mirrored keyboards, eliminate your ai opponent to win
                Difficulty increases with ball speed, more balls,
                Get more points by connecting notes with arpeggios and scales
                Power ups
                Velocity - speed at which paddle travels to note location
                Sustain - shield (limited use)
                Diamonds - act as a build up of blocks

                <h3>Abandoned Ideas</h3>
                Abandoned Ideas
                Chordle - codebreaker game similar to Wordle in which correct notes are revealed
                Melody mode
                Only rhythm could be revealed initially
                Difficulty could be increased by longer melodies
                Yellow tiles mean out of order notes
                Chord mode
                Number of notes should be known
                Difficulty could be increase by broader/more complex chords
                Yellow tiles mean wrong octave
                Once the set number of notes are played, correct/incorrect guesses are revealed
                Melodies should be from a curated list, maybe allow users to input melodies when they win?

                Whack-a-Note
                Random notes appear lit up, expecting the player to strike those notes before they disappear
                Note Break / color game
                Keys collapse as you press them
                chaining bursts of colors as groups of 3 or more connect
                Use only white notes
                Piano Hero - guitar hero-like game where notes come from a distance to be played in sync
                Would be doable if there was a way to import midi notes into processing with some extension
                Would be a good candidate for multiplayer
                Perfect Pitch - listening based game where you have to guess what note made the sound
                Could be scored with points determined by the ‘distance’ from the correct sound
                Difficulty could be increased by introducing chords
                Pitches/chords could easily be randomly generated
                Simon Says
                Self-explanatory
                Difficulty could increase by introducing multiple notes, wider octave range
                Keeps track of your streak
            </p> */}
        </Stack>)
}

