import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useMediaQuery, useTheme } from '@mui/material';

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function CustomImageList({ itemData }) {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ImageList
            variant="quilted"
            cols={matchDownMd ? 1 : 3}
            gap={0}
            sx={{
                mt: 0,
                mb: 0,
                overflowY: 'hidden',
                lineHeight: 0,
                [theme.breakpoints.down('xs')]: {
                    cols: 1,
                },
            }}
        >
            {itemData.map((item) => (
                <ImageListItem key={item.img} cols={matchDownMd ? 1 : item.cols || 1} rows={item.rows || 1}>
                    {
                        item.img ? <img
                            {...srcset(item.img, 124, item.rows, item.cols)}
                            alt={item.title}
                        /> : <video
                            width='100%' height='100%'
                            muted playsInline loop autoPlay>
                            <source src={item.video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    }
                </ImageListItem>
            ))}
        </ImageList>
    );
}
