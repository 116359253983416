import { useRef, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { Text3D, Center } from '@react-three/drei'
import { Physics, RigidBody, CuboidCollider } from '@react-three/rapier'

export default function Three() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [letterStyles, setLetterStyles] = useState({ 72: true, 69: true, 76: true, 79: true });

    //TODO add easter eggs when typing each letter
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    const handleKeyPress = (event) => {
        // Check the key code of the pressed key
        let keyCode = event.keyCode
        if ([72, 69, 76, 79].includes(keyCode)) {
            let ls = { ...letterStyles }
            ls[keyCode] = !ls[keyCode]
            setLetterStyles(ls);
        }
        // Add more conditions for other keys as needed
    };

    useEffect(() => {
        setScrollPosition(window.scrollY);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('keyup', handleKeyPress);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('keyup', handleKeyPress);
        };
    }, []);
    return (
        <Canvas orthographic dpr={[1, 2]} camera={{ position: [-20, 50, 50], zoom: 30 * (window.innerWidth / 1920) }} position='fixed' top='0' left='0' width='100vw' height='100vh' zIndex={-1} >
            <Physics gravity={[0, - 40, 0]}>
                <Letter wf={letterStyles[72]} char="H" position={[-20, 42, 0]} rotation={[0.01, 0, 0]} />
                <Letter wf={letterStyles[69]} char="E" position={[-10, 36, 0]} rotation={[0, 0, 0]} />
                <Letter wf={letterStyles[76]} char="L" position={[0, 38, 0]} rotation={[0, 0, 0]} />
                <Letter wf={letterStyles[76]} char="L" position={[10, 31, 0]} rotation={[0, 0, 0]} />
                <Letter wf={letterStyles[79]} char="O" position={[20, 34, 0]} rotation={[0, 0, 0]} />
                <ambientLight intensity={0.055} />
                <directionalLight color="red" position={[50, 0, 1]} intensity={10} />
                <directionalLight color="blue" position={[-50, 0, 1]} intensity={10} />
                <CuboidCollider position={[0, Math.min(scrollPosition * 0.003 - 10, 30), 0]} type="fixed" args={[200, 1, 200]} />
            </Physics>
        </Canvas>
    )
}

function Letter({ char, wf, children, stencilBuffer = false, ...props }) {
    const main = useRef()
    return (
        <RigidBody restitution={0.1} colliders="cuboid" {...props}>
            <Center ref={main}>
                <Text3D
                    font="/bold.blob" //TODO get own font
                    scale={0.125}
                    size={80}
                    height={20}>
                    {char}
                    <meshNormalMaterial wireframe={wf} />
                </Text3D>
            </Center>
        </RigidBody>
    )
}
