import { Link } from 'react-router-dom';
import { Stack, Typography, IconButton, Popper, Fade } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as React from 'react';

const Contacts = () => {
    const copyToClipboard = (event) => {
        const textToCopy = 'louriccia@gmail.com'; // Replace with the text you want to copy
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                //console.log('Text successfully copied to clipboard');
            })
            .catch((err) => {
                console.error('Unable to copy text to clipboard', err);
            });
        setAnchorEl(event.currentTarget);
        setOpen(true);

        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;


    return (
        <Stack direction='row' spacing={2} alignItems='center' >
            <Stack aria-describedby={id} direction='row' spacing={1} alignItems='center' justifyContent={'center'} sx={{
                padding: '5px',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                    cursor: 'pointer'
                },
                '&:hover .icon': { display: 'block' }
            }}>

                <Typography onClick={copyToClipboard}>
                    louriccia@gmail.com
                </Typography>
                <Popper id={id} open={open} anchorEl={anchorEl} transition placement='top'>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>

                            <Stack direction='row' spacing={1} alignItems='center' sx={{ padding: '5px', bgcolor: 'background.paper' }}>
                                <ContentCopyIcon />
                                <Typography>
                                    Copied to clipboard
                                </Typography>

                            </Stack>
                        </Fade>
                    )}
                </Popper>
            </Stack>
            <IconButton component={Link} to='https://www.instagram.com/louriccia' target='none'>
                <InstagramIcon />
            </IconButton>
            <IconButton component={Link} to='https://www.github.com/louriccia' target='none'>
                <GitHubIcon />
            </IconButton>
            <IconButton component={Link} to='https://www.linkedin.com/in/louis-lariccia-1a4493198/' target='none'>
                <LinkedInIcon />
            </IconButton>
        </Stack>
    )
}

export default Contacts