import { Stack, ImageList, ImageListItem, Box } from "@mui/material";

const itemData = ['1', '2', '3', '4', '7', '8', 'tb_a', 'tb_b', 'matrix'].map(name => ({ img: `/ayto/${name}.jpg`, featured: name === 'matrix' }))

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function AreYouTheOne() {
    return (
        <Stack width='100%' alignItems='center'>
            <Stack alignItems='center'>
                <video width='100%' src='/ayto/demo.mp4' autoPlay playsInline muted loop />

                <p className='copy'>
                    <h3>🤍Dating & data</h3>
                    On New Year's Eve 2021, me, my girlfriend, and some friends were trapped inside due to COVID and resorted to binge-watching <u><a href='https://en.wikipedia.org/wiki/Are_You_the_One%3F' target='none'>"Are You The One?"</a></u> on Netflix.
                    In this show, there are 10 men and 10 women who are secretly matched based on their compatibility.
                    <br /><br />
                    Each episode/round, they pair up and the host reveals how many matches they got correct.
                    Also during each round, they can use something called a "Truth Booth" to definitively know if a couple is a true match or not. If they get all matches right, they get to share $1M.
                    <br /><br />
                    As we neared the season finale, we wondered if we could deduct the true matches based on the results of previous rounds and Truth Booths. This is not at all the focus of the show (it's more about the chemistry and drama), but we were clearly very bored.
                    We began recording all the matches for each episode and all Truth Booth results on cardboard.
                </p>
                <ImageList
                    variant="quilted"
                    cols={3}
                    rowHeight={400}
                >
                    {itemData.map((item) => (
                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                            <img
                                {...srcset(item.img, 121, item.rows, item.cols)}
                                alt={item.title}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>

                <p className='copy'>
                    We weren't quite able to predict the winners, but I couldn't help but wonder if this was due to missing/incorrect data or if there was no way we could have predicted the true matches with the given data.
                    <h3>💍Project proposal</h3>
                    The following Spring term, I took a Data Visualization course with John Park at the University of Oregon.
                    <br /><br />
                    One of the assigned projects was a great fit for this dataset, so I started working on ways to visualize the events of the dating show in interesting ways, still curious if we could have predicted the ending that New Year's Eve.
                    <br /><br />
                    It quickly became clear that it would be way more interesting to make this project an interactive experience and to allow the user to construct the matches themself.
                    I double-checked my data, created a style guide, and got to work on a Processing sketch, trying to figure out how to gamify my data vis project.
                    <br /><br />
                    This was around the time that <u><a href='https://www.nytimes.com/games/wordle/index.html' target='none'>Wordle</a></u>, the 5-letter word guessing game, was becoming very popular and spawned several offshoots of similar "guess-the-passcode" style games.
                    Interestingly, John Wardle, inventor of Wordle, also attended the University of Oregon.
                </p>

                <img width='100%' src='/ayto/colortest.png' alt='color test' />
                <p className='copy'>
                    <h3>👫A difficult engagement</h3>

                    As I started testing my matchmaker game, which I had so faithfully modeled after the show's format, I realized how difficult it was to play.
                    When the show was conceived, they were probably counting on the in-person chemistry of the contestants to help them out in the initial rounds.
                    The player in my game, however, has nothing to go on except photos, and is left to make uneducated guesses.
                    <br /><br />
                    On top of that, in contrast with games like Wordle, in which all letters are authenticated each round, my "letters" were only authenticated one match at a time in an in-between round Truth Booth.
                    <br /><br />
                    As much as this should have dissuaded me, I thought of ways I could aid the player to help balance the game.
                    <br /><br />
                    I started implementing some helpful mechanics and safeguards. For example, the user can't match two people the Truth Booth has already revealed to not be a match.
                    Guesses were also retroactively marked correct or incorrect based on all Truth Booth results, however, the player is still allowed to intentionally make an incorrect guess since it is sometimes strategic to do so.
                    <br /><br />
                    Another extremely helpful change was to make it so that when the player picks up a photo or hovers over the guess board, that photo is highlighted across the board to show where it's been.
                    Originally the board was covered in full color faces which was visually overwhelming. Making most of the photos grayscale drastically improved the visual clarity.
                    <h3>💖Honeymoon phase</h3>
                    Once I had addressed the difficulty, the game was still by no means easy, but it was playable. In 10 rounds I could reasonably guess the correct matches as long as I wasn't too unlucky.
                    <br /><br />
                    For the final touches, once the player finishes the game, they can view the actual results of the show and start a new game with randomized matches.
                </p>
                <video width='100%' src='/ayto/demo.mp4' autoPlay playsInline muted loop />
                <p className='copy'>
                    <h3>👀Long last look</h3>
                    Making the data visualization an interactive experience not only got the player more invested in the data, but it also secretly taught them how to read the data.
                    However, the gamification did introduce a heap of design challenges.
                    <br /><br />
                    When I play the game now, I am still thinking of ways to improve the gameplay.
                    I see a lot of potential in borrowing mechanics from Sudoku apps.
                    <br /><br />
                    Perhaps the player could use a Sudoku-style notes system that allows them to mark possible matches.
                    And like Sudoku, the notes could be user-managed or automatically filled in depending on a selected option.
                </p>
            </Stack>
        </Stack>)
}

