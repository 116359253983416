// themes.js

import { createTheme } from '@mui/material/styles';

// Light Theme
export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    typography: {
        fontFamily: 'Poppins, sans-serif',
        h1: {
            fontWeight: 'bold',
            letterSpacing: -4
        },
        a: {
            color: "black",
            hover: "grey"
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    color: 'black', // Specify your desired font color
                },
            },
        },
    },
    
});

// Dark Theme
export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: 'Poppins, sans-serif',
        h1: {
            fontWeight: 'bold',
            letterSpacing: -4
        }
    },
});