import { Box, Button, Stack } from "@mui/material";
import ProjectFeed from "../components/ProjectFeed";
import FadeUp from "../components/FadeUp";
import CustomLink from "../components/CustomLink";
export default function Projects() {
    return (
        <div>

            <Stack alignItems='center' padding={3} maxWidth='100vw'>
                <Box height='100px' />
                <FadeUp >
                    <ProjectFeed />
                </FadeUp>
                <Box mt={12} alignItems='center' >
                    <Button sx={{ width: '100%', backgroundColor: 'primary', color: '#FFFFFF' }} className='shadow' component={CustomLink} to='/gallery'>
                        Check out the Gallery
                    </Button>
                </Box>
            </Stack>
        </div>
    )
}

