const { default: AdminWizard } = require("../pages/_Projects/AdminWizard");
const { default: FN3 } = require("../pages/_Projects/FN3");
const { default: InfiniteImage } = require("../pages/_Projects/InfiniteImage");
const { default: PianoArcade } = require("../pages/_Projects/PianoArcade");
const { default: SpreezyMart } = require("../pages/_Projects/SpreezyMart");
const { default: CompanyDashboard } = require("../pages/_Projects/CompanyDashboard");
const { default: AreYouTheOne } = require("../pages/_Projects/AreYouTheOne");
const { default: Botto } = require("../pages/_Projects/Botto");
const { default: LevelExplorer } = require("../pages/_Projects/LevelExplorer");
exports.projects = [
    {
        title: 'A Lively Prototype for a Dead Company',
        subtitle: 'Lessons learned from designing for a failed startup',
        img: '/fn_logo.png',
        skills: ['Figma', 'Illustrator', 'Lottie'],
        year: 2024,
        key: 'fleet_nurse_3',
        content: FN3()
    },
    {
        title: 'When a Temporary Solution Becomes a Permanent One',
        subtitle: 'How a UI/UX overhaul saved the company... briefly',
        skills: ['Bubble'],
        year: 2023,
        key: 'admin_wizard',
        img: '/admin_wizard.png',
        content: AdminWizard()
    },
    {
        title: 'Ad Inifinitum → Ad Nauseam',
        subtitle: 'How I made a hypnotizing tour through endless AI slop',
        skills: ['Processing', 'RunwayML'],
        year: 2023,
        key: 'infinite_image',
        img: '/infinite.png',
        content: InfiniteImage()
    },
    {
        title: 'Controller or Keyboard?',
        subtitle: 'Prototyping and programming a projected piano playground powered by Processing',
        skills: ['Processing', 'MadMapper', 'MIDI'],
        year: 2022,
        key: 'piano_arcade',
        img: '/piano_arcade.jpg',
        content: PianoArcade()
    },
    {
        title: 'Tableau and Duct Tape',
        subtitle: 'A beautiful company-wide analytics dashboard with a not-so-beautiful backend',
        skills: ['Tableau', 'Node JS', 'PostgreSQL'],
        year: 2022,
        key: 'fn_dashboard',
        img: '/fndashboard.png',
        content: CompanyDashboard()
    },
    {
        title: 'Parody is Convenient',
        subtitle: 'A pop-up t-shirt shop themed as a parody convenience store',
        skills: ['Illustrator', 'After Effects', 'Google Sites', 'Cutting Master 4',],
        year: 2022,
        key: 'spreezy_mart',
        img: '/spreezy_mart.png',
        content: SpreezyMart()
    },
    {
        title: 'Data-Based Matchmaking',
        subtitle: 'A Wordle rip-off based on a trashy dating show',
        skills: ['Processing', 'Illustrator'],
        year: 2022,
        key: 'are_you_the_one',
        img: '/ayto_title.png',
        content: AreYouTheOne()
    },
    // {
    //     title: "Started with the Bot Now We're Here",
    //     subtitle: 'How a lowly Discord bot grew into a community-fostering powerhouse',
    //     skills: ['Discord JS', 'OpenAI', 'Heroku', 'Node JS', 'Firebase'],
    //     year: 2024,
    //     key: 'botto',
    //     img: '/botto.png',
    //     content: Botto()
    // },
    // {
    //     title: 'The Long Road to a Track Editor',
    //     subtitle: 'Making a Blender add-on to create custom levels for a 1999 Star Wars racing game',
    //     skills: ['Blender', 'Blender PY', 'Reverse Engineering'],
    //     year: 2024,
    //     key: 'level_explorer',
    //     img: '/batp.png',
    //     content: LevelExplorer()
    // }

]

